<template>
  <div class="edit_form container mt-4">
    <h1>{{ t("user.user") }} {{ t("general.delete") }}</h1>
    <p>{{ t("user.deleteThisUser") }}?</p>
    <dl v-if="user">
      <dt>{{ t("user.email") }}</dt>
      <dd>{{ user.email }}</dd>
      <dt>{{ t("user.name") }}</dt>
      <dd>{{ user.name }}</dd>
    </dl>
    <div v-if="error" class="w-100 text-center alert alert-danger mb-3" role="alert">
      <span v-if="error.message == 'The given data was invalid.'">{{ t("error.invalidData") }}</span>
      <span v-else>{{ error.message }}</span>
    </div>
    <form v-if="user" ref="form" @submit.prevent="onSubmit" class="needs-validation">
      <button type="button" @click="save" class="btn btn-primary me-2">{{ t("general.delete") }}</button>
      <button type="button" @click="cancel" class="btn btn-danger">{{ t("general.cancel") }}</button>
    </form>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n/index";
import axios from "axios";
import storeConfig from "../../store/config";
import { useToast } from "vue-toastification";
export default {
  name: "UserEdit",
  setup() {
    const error = ref(null);
    const store = useStore();

    const form = ref(null);

    const route = useRoute();
    const id = route.params.id;

    if (store.getters.jwt) {
      store.dispatch("loadUser", route.params.id);
    }

    const toast = useToast();
    const router = useRouter();
    const { t } = useI18n();

    async function save() {
      try {
        await store.dispatch("addLoading", 1);
        await axios.delete(storeConfig.apiBaseUrl + "user/" + id);
        store.dispatch("addLoading", -1);
        toast.success(t("user.user") + " " + t("general.successfully") + " " + t("general.deleted") + ".");
        router.push("/user");
      } catch (err) {
        error.value = err.response.data;
      }
    }

    function cancel() {
      router.push("/user");
    }

    return {
      form,
      id,
      error,
      save,
      cancel,
      user: computed(() => store.getters.editUser),
      jwt: computed(() => store.getters.jwt),
      t,
    };
  },
};
</script>
